import { AppApi } from './app.api';
import { AuthApi } from './auth.api';
import { BroadcastsApi } from './broadcasts.api';
import { ChannelsApi } from './channels.api';
import { CustomersApi } from './customers.api';
import { ListsApi } from './lists.api';
import { TemplatesApi } from './templates.api';
import { UsersApi } from './users.api';
import { ChatsApi } from './chats.api';
import { TariffsApi } from './tariffs.api';
import { SubscriptionsApi } from './subscriptions.api';
import { PaymentsApi } from './payments.api';
import { FieldsApi } from './fields.api';
import { ProfileApi } from './profile.api';
import { ValuesApi } from './values.api';
import { ChatbotsApi } from './chatbots.api';
import { ChatbotsStepApi } from './chatbots-step.api';
import { TriggerBroadcastApi } from './trigger-broadcast.api';
import { ChatbotsConnectionsApi } from '~/api/chatbots-connections.api';
import { CompanyApi } from '~/api/company.api';
import { ContactsApi, UploadContactsApi } from './contacts.api';

export interface FindOptions {
  relations?: string[];
  fields?: string[];
  order?: Record<string, any>;

  take?: number;
  skip?: number;

  withMeta?: boolean;
}

export const defaultApi = {
  take: 25,
  skip: 0,
};

const createApi = () => {
  const contactsApi = new ContactsApi();

  return {
    app: new AppApi(),
    auth: new AuthApi(),
    customers: new CustomersApi(),
    lists: new ListsApi(),
    users: new UsersApi(),
    broadcasts: new BroadcastsApi(),
    channels: new ChannelsApi(),
    chatbots: new ChatbotsApi(),
    chatbotsStep: new ChatbotsStepApi(),
    chatbotsConns: new ChatbotsConnectionsApi(),
    templates: new TemplatesApi(),
    chats: new ChatsApi(),
    tariffs: new TariffsApi(),
    subscriptions: new SubscriptionsApi(),
    payments: new PaymentsApi(),
    fields: new FieldsApi(),
    profile: new ProfileApi(),
    values: new ValuesApi(),
    triggerBroadcast: new TriggerBroadcastApi(),
    company: new CompanyApi(),
    contacts: contactsApi,
    uploadContacts: new UploadContactsApi(contactsApi),
  };
};

export const api = createApi();
