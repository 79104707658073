<script lang="ts" setup>
import { onMounted, computed } from 'vue';
import ProfileIndex from '~/components/profileV2/ProfileIndex.vue';
import ProfileWzIndex from '~/components/profileV2/ProfileWzIndex.vue';

import { useProfile } from '~/stores/profile.store';
import { useUser } from '~/stores/user.store';

const profileStore = useProfile();
const userStore = useUser();

const componentId = computed(() => (userStore.user.isWzIntegration ? ProfileWzIndex : ProfileIndex));

const init = async () => {
  await profileStore.initProfile();
};

onMounted(init);
</script>

<template>
  <GreetingNewUserModal />

  <div class="profile-wrapper">
    <div class="loader-wrapper" v-if="profileStore.getFetching">
      <BasePreloader class="preloader" />
    </div>

    <component v-else :is="componentId"></component>
  </div>
</template>

<style scoped>
.loader-wrapper {
  height: 100%;
  position: relative;

  .preloader {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.profile-wrapper {
  box-sizing: border-box;
  padding: 32px 40px;
  background-color: var(--common-white);
  height: calc(100vh - 64px);
  overflow-y: auto;
}
</style>
