import { Channel } from '~/entities/channel';

export enum ChatbotsStatus {
  active = 'active',
  ready = 'ready',
  draft = 'draft',
  test = 'test',
}

export enum ChatBotStepType {
  // шаг-условие
  step = 'step',
  // действие
  action = 'action',
  // timeout step
  timeout = 'timeout',
  // start step
  start = 'start',
  // шаг отправка вебхука во вне
  webhook = "webhook",
  // шаг остановки бота
  end = 'end',
}

export interface ChatbotRequestBody {
  name: string;
  priority: number;
  companyId: string;
  channelId: string;
}

export interface Chatbot extends Record<string, any> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  status: ChatbotsStatus;
  name: string;
  priority: number;
  channelId: string;

  connections?: ChatbotConnection[];
  steps?: ChatbotStep[];
  channel?: Channel;
}

export interface ChatbotStep {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  function: any;
  type: ChatBotStepType;
  coords: any;
  state: any;
}

export interface ChatbotConnection {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  outputKey: string | null;
  stepFromId: string | null;
  stepToId: string | null;

  stepTo?: ChatbotStep;
  stepFrom?: ChatbotStep;
}
