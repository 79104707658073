import { defineStore } from 'pinia';
import { saveAs } from 'file-saver';

import { api, defaultApi, FindOptions } from '~/api';
import {
  Contact,
  ContactField,
  CustomField,
  CustomFieldValue,
  SystemField,
  SystemFieldType,
  SystemFieldValue,
  TagEntity,
} from '~/entities/contacts';
import { FilterBuilder } from '~/components/customers/filter-builder';
import { InclusionsExclusions } from '~/entities/customer';
import { useCompany } from '~/stores/company.store';

interface FieldValueMap {
  [customerId: string]: {
    [fieldId: string]: SystemFieldValue | CustomFieldValue;
  };
}

interface State {
  // data
  systemFields: SystemField[];
  customFields: CustomField[];
  contacts: Contact[];
  tags: TagEntity[];
  fieldValueMap: FieldValueMap;
  noValueFields: ContactField[];

  // counters
  /**
   * Количество всех контактов у пользователя
   */
  countAll: number;
  /**
   * Количество записей после фильтрации
   */
  countFiltered: number;

  // filters
  filterBuilder: FilterBuilder;
  filterValues: { [fieldId: string]: { [key: string]: unknown } };

  // table checkboxes
  /**
   * Флаг выбора всех контактов
   */
  isAllSelected: boolean;
  /**
   * Объет для отслежевания выбора (чекбоксы)
   *
   * TODO: возможно можно подмешать в customers флаг чекбокса
   */
  selections: Record<string, boolean>;
  /**
   * Режим чекбоксов
   */
  selectionMode: 'all' | 'none';

  loading: boolean;
}

const extractFieldValue = (contacts: Contact[]): FieldValueMap => {
  return contacts.reduce((acc: FieldValueMap, contact: Contact) => {
    const { id, customFieldValues, sysFieldValues } = contact;

    if (!acc[id]) acc[id] = {};

    customFieldValues.forEach((customFieldValue) => {
      acc[id][customFieldValue.customField.id] = customFieldValue;
    });

    sysFieldValues.forEach((sysFieldValue) => {
      acc[id][sysFieldValue.sysField.id] = sysFieldValue;
    });

    return acc;
  }, {});
};

export const useContacts = defineStore('contacts', {
  state: (): State => ({
    customFields: [],
    systemFields: [],
    contacts: [],
    tags: [],
    fieldValueMap: {},
    noValueFields: [],

    countAll: 0,
    countFiltered: 0,

    filterBuilder: new FilterBuilder(),
    filterValues: {},

    isAllSelected: true,
    selections: {},
    selectionMode: 'all',

    loading: false,
  }),

  getters: {
    getContacts(): Contact[] {
      return this.contacts;
    },
    getSystemFields(): SystemField[] {
      return this.systemFields;
    },
    getCustomFields(): CustomField[] {
      return this.customFields;
    },
    getEntireFields(): Array<ContactField> {
      return [...this.getSystemFields, ...this.getCustomFields];
    },
    getCount(): { all: number; filtered: number } {
      return { all: this.countAll, filtered: this.countFiltered };
    },
    getSelectionsCount: (state): number => {
      if (state.isAllSelected) {
        return state.countFiltered;
      } else {
        if (state.selectionMode === 'all') {
          const uncheckedCount = Object.values(state.selections).filter(
            (item) => !item,
          ).length;
          return state.countFiltered - uncheckedCount;
        }
      }

      return Object.values(state.selections).filter(Boolean).length;
    },
    getTags(): TagEntity[] {
      return this.tags;
    },
    getNoValueFieldMap: (state): Record<string, string> => {
      return state.noValueFields.reduce(
        (filedIds, field) => ({ ...filedIds, [field.id]: field.id }),
        {},
      );
    },
  },

  actions: {
    /* async actions */
    async reloadContacts(saveOldSelections = false): Promise<void> {
      this.loading = true;

      await Promise.all([
        this.fetchMetaContacts(saveOldSelections),
        this.fetchFields(),
        this.fetchTags(),
      ]);

      this.loading = false;
    },
    async downloadContactsAsCsv() {
      const result = await api.contacts.download({
        ...this.getInclusionsExclusions(),
        filters: this.filterBuilder.filter,
      });

      saveAs(result, 'contacts.csv');
    },

    // contacts
    async initContacts(): Promise<void> {
      if (this.getContacts.length) return;

      await this.fetchContacts({
        take: defaultApi.take,
        skip: defaultApi.skip,
      });
    },
    async fetchContacts({ skip, take }: FindOptions): Promise<void> {
      const { contacts } = await api.contacts.getContacts(
        this.filterBuilder.filter,
        { take, skip },
      );

      this.contacts = [...this.contacts, ...contacts];
      this.fieldValueMap = {
        ...this.fieldValueMap,
        ...extractFieldValue(contacts),
      };
      const newCheckboxes = contacts.reduce((accumulator, { id }) => {
        return { ...accumulator, [id]: this.selectionMode === 'all' };
      }, {});
      this.selections = { ...this.selections, ...newCheckboxes };
    },
    async fetchMetaContacts(saveOldSelections: boolean): Promise<void> {
      const { contacts, countAll, countFiltered } =
        await api.contacts.getMetaContacts(this.filterBuilder.filter);

      this.countAll = countAll;
      this.countFiltered = countFiltered;
      this.contacts = contacts;
      this.fieldValueMap = extractFieldValue(contacts);

      const selectionsMap = contacts.reduce((accumulator, { id }) => {
        return { ...accumulator, [id]: this.selectionMode === 'all' };
      }, {});
      if (saveOldSelections) {
        this.selections = {
          ...selectionsMap,
          ...this.selections,
        };
      } else {
        this.selections = { ...selectionsMap };
      }
    },

    // fields
    async fetchFields(): Promise<void> {
      const { sysFields, customFields } = await api.contacts.getFields();
      this.systemFields = sysFields
        .filter((field) => field.name !== 'tg_id')
        .map((field) => ({
          ...field,
          type:
            field.name === 'birthday' ? SystemFieldType.Birthday : field.type,
        }));
      this.customFields = customFields;
    },
    async fetchFieldsWithoutValue(): Promise<void> {
      const companyStore = useCompany();

      this.noValueFields = await api.contacts.getNoValueFields({
        ...this.getInclusionsExclusions(),
        filters: this.filterBuilder.filter,
        companyId: companyStore.company.id,
      });
    },
    async updateField(payload: {
      fieldId: string;
      name: string;
    }): Promise<void> {
      const index = this.customFields.findIndex(
        ({ id }) => id === payload.fieldId,
      );

      if (index !== -1) {
        await api.contacts.updateField(payload.fieldId, payload.name);

        const updated = { ...this.customFields[index], name: payload.name };
        this.customFields.splice(index, 1, updated);
      }
    },
    async removeField(fieldId: string): Promise<void> {
      const index = this.customFields.findIndex(({ id }) => id === fieldId);

      if (index !== -1) {
        await api.contacts.removeField(fieldId);

        this.customFields.splice(index, 1);
      }
    },
    async updateValues(payload: {
      systemFieldId?: string;
      customFieldId?: string;
      value: string;
    }) {
      const inclusionsExclusions = this.getInclusionsExclusions();
      await api.contacts.updateFieldValues({
        ...payload,
        ...inclusionsExclusions,
        filters: this.filterBuilder.filter,
      });
    },

    // tags
    async fetchTags(): Promise<void> {
      this.tags = await api.contacts.getTags();
    },
    /**
     * Массовое присвоение тегов у контактов, при массовом редактировании
     */
    async assignMassTags(payload: {
      tagIds: string[];
      newTags: string[];
    }): Promise<void> {
      const newTags = payload.newTags.map((name) =>
        api.contacts.createTag(name),
      );
      const newTagsResult = newTags.length
        ? await Promise.all(newTags).then((value) => value.map(({ id }) => id))
        : [];

      const inclusionsExclusions = this.getInclusionsExclusions();

      await api.contacts.assignContactTags({
        ...inclusionsExclusions,
        tagIds: [...payload.tagIds, ...newTagsResult],
        filters: this.filterBuilder.filter,
      });
    },
    /**
     * Точечное удаление тега у контакта
     */
    async removeTag(payload: {
      contactTagId: string;
      contactId: string;
    }): Promise<void> {
      const { success, tagCompletelyDeleted } =
        await api.contacts.removeContactTag(payload.contactTagId);
      if (!success) return;

      const contactIndex = this.getContacts.findIndex(
        ({ id }) => id === payload.contactId,
      );
      const { tag } = this.getContacts[contactIndex].tags.find(({ id }) => {
        return id === payload.contactTagId;
      })!;
      const currentTag = { ...tag };
      this.getContacts[contactIndex].tags = this.getContacts[
        contactIndex
        ].tags.filter(({ id }) => {
        return id !== payload.contactTagId;
      });

      // Если тег был удален полностью, то вычищаем его из общего списка
      if (tagCompletelyDeleted) {
        this.tags = this.tags.filter(({ id }) => id !== currentTag.id);
      }
    },
    /**
     * Массовое удаление тегов у контактов, при массовом редактировании
     */
    async removeMassTags(tagIds: string[]): Promise<void> {
      const inclusionsExclusions = this.getInclusionsExclusions();

      await api.contacts.removeContactTags({
        ...inclusionsExclusions,
        tagIds,
        filters: this.filterBuilder.filter,
      });
    },

    /* actions */

    /**
     * Возвращает массив ID контактов, которые необходимо включить/исключить
     * при формировании списка для операций (массовая рассылка и т.п.)
     *
     * Используя флаг выбора всех контактов имеем следующее:
     * - если выбраны все контакты, то ничего включать/исключать не нужно
     *
     * - если флаг выбора всех контактов активен (selectionMode в 'all'), значит нам достаточно только исключить
     * известные записи из списка (в этом случае мы вынуждены убирать чекбоксы ручками)
     *
     * - если флаг выбора всех контактов был сброшен (selectionMode в 'none'), значит список selected имеет все необходимые нам контакты,
     * поэтому достаточно передать только их
     */
    getInclusionsExclusions(): InclusionsExclusions {
      if (this.isAllSelected) {
        return {
          included: [],
          excluded: [],
        };
      }

      const selections = Object.entries(this.selections);

      if (this.selectionMode === 'all') {
        const unselected = selections.filter(([_, value]) => !value);
        const excludedCustomerIds = unselected.map(
          ([customerId]) => customerId,
        );
        return { excluded: excludedCustomerIds };
      } else {
        const selected = selections.filter(([_, value]) => value);
        const included = selected.map(([customerId]) => customerId);
        return { included };
      }
    },
    getFieldValue(payload: {
      contactId: string;
      fieldId: string;
    }): SystemFieldValue | CustomFieldValue {
      return this.fieldValueMap[payload.contactId][payload.fieldId];
    },
    setFilterValues(value: { [fieldId: string]: unknown }, fieldId: string) {
      this.filterValues[fieldId] = value;
    },
    deleteFilterValue(fieldId: string) {
      const newVal = { ...this.filterValues };
      delete newVal[fieldId];
      this.filterValues = newVal;
    },
  },
});
