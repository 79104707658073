import { ref } from 'vue';

import { ChatBotGateway, ChatBotCallback } from '~/composables/chatbot-ws';

import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const useChatbotsWs = defineStore('chatbotsWs', () => {
  const DEBAG_FLAG = useStorage('DEBUG_MISHA', false);

  // entry point for socket
  const sk = ref<any>(null);

  // callbacks
  const onSuccessCreateNode = ref<any>(null);
  const onSuccessCreateConnection = ref<any>(null);
  const onSuccessCloseConnectionChain = ref<any>(null);

  // debug tmp vars
  const connectionsWithShadowStepForDebug = ref<any>([]);

  /**
   * callback - обработчик для успешного сообщения от сокета
   * @param obj {ChatBotCallback}
   */
  const callback = ({ data, method }: ChatBotCallback) => {
    switch (method) {
      case 'create': {
        onSuccessCreateNode.value(data);
        break;
      }

      case 'copy': {
        onSuccessCreateNode.value(data);
        break;
      }

      case 'create-connection': {
        if (DEBAG_FLAG.value) {
          console.log('%c✅ DEBUG MISHA (создание связи)', 'color: yellowgreen; font-size: 16px;');
          console.log(`%c${data.type}:${data.id}`, 'color: yellowgreen;');
          if (data['function']['if'].length) {
            console.table(data['function']['if']);
          } else {
            console.log(`%ctimeout = ${data.function.send.timeout}`, 'color: yellowgreen;');
          }
        }
        onSuccessCreateConnection.value(data);
        break;
      }

      case 'update-connection': {
        if (DEBAG_FLAG.value) {
          console.log('%c✅ DEBUG MISHA (обновление связей)', 'color: orange; font-size: 16px;');
          data.forEach((d: any) => {
            console.log(`%c${d.type}:${d.id}`, 'color: orange;');
            if (d['if'].length) {
              console.table(d['if']);
            } else {
              console.log(`%ctimeout = ${d.timeout}`, 'color: orange;');
            }
          });
        }
        break;
      }

      case 'close-connection-chain': {
        onSuccessCloseConnectionChain.value(data);
        break;
      }

      default: {
        break;
      }
    }
  };

  /**
   * callbackError - обработчик для ошибки
   * @param obj {ChatBotCallback}
   */
  const callbackError = ({ data, method }: ChatBotCallback) => {
    console.log('🔥 skCallbackError', data, method);
  };

  /**
   * init - инициализация канала сокета
   * @param chatBotId {string}
   * @param successCreateNode {func}
   */
  const init = ({
    chatBotId,
    successCreateNode,
    successCreateConnection,
    successCloseConnectionChain,
  }: {
    chatBotId: string;
    successCreateNode: any;
    successCreateConnection: any;
    successCloseConnectionChain: any;
  }) => {
    sk.value = new ChatBotGateway({
      chatBotId: chatBotId,
      callback,
      callbackError,
    });
    sk.value.receive();

    // sk.value.send('create', 'asd');

    // init callbacks
    onSuccessCreateNode.value = successCreateNode;
    onSuccessCloseConnectionChain.value = successCloseConnectionChain;
    onSuccessCreateConnection.value = successCreateConnection;
  };

  /**
   * disconnect - отключение от канала
   */
  const disconnect = () => {
    sk.value?.disconnect();
    sk.value = null;
  };

  /**
   * send - отправка сообщения по сокету
   * @param method {string}
   * @param data {Object} - backend json
   * @returns {callback}
   */
  const send = (method: string, data: any) => {
    sk.value.send(method, data);
  };

  const updateConnectionsWithShadowStepForDebug = (newArr: any) => {
    connectionsWithShadowStepForDebug.value = newArr;
  };

  return {
    init,
    disconnect,
    send,
    connectionsWithShadowStepForDebug,
    updateConnectionsWithShadowStepForDebug,
  };
});
