import { useAuth } from "~/stores/auth.store";
import { useChannels } from "~/stores/channel.store";
import { useContacts } from "~/stores/contacts.store";
import { useUser } from "~/stores/user.store";

export const authPaths = ['/login', '/register', '/login/confirm', '/register/confirm', '/error']

export const initUser = async () => {
  const authStore = useAuth();
  if (!authStore.isInited && authStore.authorized) {
    await authStore.fetchUser();
    authStore.setInit(true)
    const userStore = useUser();
    await userStore.checkWzApiKey();

    const { fetchChannels } = useChannels();
    const { initContacts } = useContacts();

    await fetchChannels();
    await initContacts();
  }
};
