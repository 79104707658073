export enum CustomFieldType {
  Date = 'date',
  Text = 'text',
  Int = 'int',
}

export enum SystemFieldType {
  Phone = 'phone',
  String = 'string',
  Number = 'number',
  Date = 'date',
  Enum = 'enum',
  Birthday = 'birthday',
}

interface Field {
  id: string;
  name: string;
}

export interface Contact {
  id: string;
  companyId: string;
  customFieldValues: CustomFieldValue[];
  sysFieldValues: SystemFieldValue[];
  tags: { tag: TagEntity; id: string }[];
}

export interface TagEntity {
  id: string;
  name: string;
  color: string | null;
}

export interface CustomField extends Field {
  type: CustomFieldType;
}

export interface CustomFieldValue {
  customField: CustomField;
  value: string;
}

export interface SystemField extends Field {
  type: SystemFieldType;
  name: SysFieldName;
}

export interface SystemFieldValue {
  sysField: SystemField;
  value: string;
}

export type ContactField = SystemField | CustomField;

export type TagType = 'TAG';

export interface TagField {
  id: TagType;
  name: TagType;
  type: TagType;
}

export enum SysFieldName {
  Phone = 'phone',
  Tg_Nick = 'tg_nick',
  Tg_Id = 'tg_id',
  Name = 'name',
  Status = 'status',
  UploadMethod = 'upload_method',
  UploadDate = 'created_at',
  UpdateDate = 'updated_at',
  LastSend = 'sended_at',
  Birthday = 'birthday',
}
