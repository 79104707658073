import { Socket, connect } from 'socket.io-client';
import { DefaultEventsMap } from '@socket.io/component-emitter';
import { config } from '~/config';
import { Router } from 'vue-router';
import router from '~/router';
import { useToast } from '@profeat/ui-kit';

interface ChatBotProps {
  chatBotId: string;
  callback?: any;
  callbackError?: any;
}

export interface ChatBotCallback {
  data: any;
  method: string;
  message?: string;
}

export enum ChatBotCommands {
  update = 'update',
  create = 'create',
  get = 'get',
  delete = 'delete',
  saveSchema = 'save-schema',
}

/**
 * @example
 * const sk = new ChatBotGateway(obj: ChatBotProps);
 * sk.send('update', 'hello');
 * sk.receive();
 */
export class ChatBotGateway {
  private socket: Socket<DefaultEventsMap, DefaultEventsMap>;
  private messages: Record<ChatBotCommands, any[]>;
  private router: Router;

  public callback = ({ data, method, message = 'socket' }: ChatBotCallback) => {
    console.log('🎸 ' + message);
    console.log(`%c\t ${method}`, 'color: green;', data);
  };

  public callbackError = ({ data, method, message = 'socket' }: ChatBotCallback) => {
    console.error('🦕 ' + message, data, method);
  };

  constructor({ chatBotId, callback, callbackError }: ChatBotProps) {
    const socketUrl = config.socketUrl || '/api';

    this.router = router;
    this.messages = {
      update: [],
      create: [],
      delete: [],
      get: [],
      'save-schema': [],
    };

    this.socket = connect(socketUrl, {
      query: { chatBotId },
      path: '/api/cb',
      transports: ['websocket'],
    });

    this.callback = callback || this.callback;
    this.callbackError = callbackError || this.callbackError;

    this.init();
  }

  disconnect() {
    this.socket.disconnect();
    this.socket.removeAllListeners();
  }

  send(cmd: ChatBotCommands, data: any) {
    if (!this.socket.connected) {
      this.callbackError({
        data,
        method: cmd,
        message: 'socket not connected, will try later',
      });
      if (this.messages[cmd]) this.messages[cmd].push(data);
      else this.messages[cmd] = [data];

      return;
    }

    this.socket.emit(cmd, data);
  }

  receive() {
    this.socket.on('receive_message', (data: any) => {
      this.callback({
        data: data.data,
        method: data.method,
        message: 'receive_message',
      });
    });
  }

  private checkStackMessages() {
    for (const [cmd, value] of Object.entries(this.messages)) {
      if (value.length > 0) {
        value.forEach((data) => {
          console.log('socket messages from stack', cmd, data);
          this.send(cmd as ChatBotCommands, data);
        });
      }
    }
  }

  private init() {
    this.socket.on('disconnect', async () => {
      if (this.router.currentRoute.value.name === 'ChatBotsEditor') {
        const { showToast } = useToast();
        showToast({
          text: 'Вы слишком долго бездействовали! Пожалуйста, перезайдите в редактор',
          color: 'error',
        });

        await this.router.push('/chat-bots');
      }
    });

    this.socket.on('connect', () => {
      this.checkStackMessages();
    });
  }
}
