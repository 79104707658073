import { useToast } from "@profeat/ui-kit";
import { defineStore } from "pinia";
import { AbstractNode } from "@baklavajs/core";

import { api } from "~/api";
import {
  Chatbot,
  ChatbotRequestBody,
  ChatbotsStatus,
  ChatbotStep,
  ChatBotStepType,
} from "~/entities/chatbots";
import { NotificationType, useNotification } from "~/stores/notification.store";
import { useTemplates } from "~/stores/template.store";
import { useUser } from "~/stores/user.store";
import { useChatbotsWs } from "~/stores/chatbots-ws.store";
import { TemplateCategoryType } from "~/entities/template";
import { $t } from "~/i18n";
import { v4 as uuidv4 } from "uuid";
import { isUrl } from "~/utils/string-helper";
import { useContacts } from '~/stores/contacts.store';
import { ChannelsTransportType } from '~/entities/channel';

export type ChatBotStartType = "incoming" | "outgoing" | "telegram";

type ChatBotType = Chatbot & {
  start: ChatBotStartType | null;
  templateText: string | null;
};
type Chatbots = Array<ChatBotType>;

export interface ChatBotTestingDTO {
  chatBotId: string;
  phone?: string;
  schema?: string
}

interface IConnection {
  stepFromId: string;
  stepToId: string;
  outputKey: string;
  // ID невидимого-теневого STEP'а который связывает между собой два шага
  shadowStepId: string;
}

interface ISchema {
  steps: ChatbotStep[];
  connections: IConnection[];
}

interface State {
  chatbots: Chatbots;
  steps: ChatbotStep[];
  templateChatBots: {
    [templateId: string]: { chatBotId: string; chatBotName: string }[];
  };
  workingDays: string[];
  isFetching: boolean;
  currentChatbot: ChatBotType;
  notClosedConnections: string[];
  isIntegrity: boolean;
  showTestModal: boolean;
  isLoadingFlagForTestModal: boolean;
  templateStartType: boolean;
  templateButtonVariables: Record<string, Record<string, string>>;
  stats: boolean;
  hasStats: boolean;
  stepStatistics: {
    [stepId: string]: { statistics: any; outputStatistics: any };
  };
  baklava: any;
  schema: ISchema | undefined;
  connections: IConnection[] | undefined;
  isActivateInstantly: boolean;
}

export const useChatbots = defineStore("chatbots", {
  state: (): State => ({
    chatbots: [],
    steps: [],
    templateChatBots: {},
    workingDays: [],
    isFetching: false,
    currentChatbot: {} as ChatBotType,
    notClosedConnections: [],
    isIntegrity: true,
    showTestModal: false,
    isLoadingFlagForTestModal: true,
    templateStartType: false,
    templateButtonVariables: {},
    stats: false,
    hasStats: false,
    stepStatistics: {},
    baklava: undefined,
    schema: undefined,
    connections: undefined,
    isActivateInstantly: false,
  }),

  actions: {
    setBaklava(value: any) {
      this.baklava = value;
    },
    setSchema(value: any) {
      this.schema = value;
    },
    setConnections(val: IConnection[]) {
      this.connections = val;
    },
    setStats(value: boolean) {
      this.stats = value;
    },
    setHasStats(payload: boolean) {
      this.hasStats = payload;
    },

    async createNewChatBot(body: ChatbotRequestBody) {
      const { data } = await api.chatbots.createChatBot(body);
      return data as Chatbot;
    },

    async updateWorkingDays(days: string[]) {
      this.workingDays = days;
    },

    async fetchTemplateStartType() {
      const res = await api.chatbots.fetchTemplateStartType(
        this.currentChatbot.id
      );
      this.templateStartType = res.data;
    },

    async fetchBotsByTemplate() {
      this.templateChatBots = await api.chatbots.getChatBotsTemplate();
    },

    async testing(phoneNumber?: string) {
      await api.chatbots.update(this.getCurrent.id, {
        status: ChatbotsStatus.test,
      });
      await api.chatbots.testing({
        phone: phoneNumber,
        chatBotId: this.getCurrent.id,
        schema: JSON.stringify(this.schema)
      });
    },

    async sendTestBroadcast(phoneNumber: string) {
      const { showToast } = useToast();
      try {
        await api.chatbots.sendTestingBroadcast(
          this.getCurrent.id,
          phoneNumber.replace("+", "")
        );
        showToast({
          text: "Рассылка отправлена успешно",
          color: "success",
        });
      } catch (err) {
        showToast({
          text: "Не получилось отправить рассылку",
          color: "error",
        });
      }
    },

    async fetchChatbots() {
      const templateStore = useTemplates();

      await templateStore.fetch();
      const response = await api.chatbots.findMany<Chatbot[]>();

      if (Array.isArray(response?.data)) {
        this.chatbots = response.data
          .map((chatBot) => {
            if (
              chatBot.baklava_schema !== undefined &&
              chatBot.baklava_schema !== null
            ) {
              const startStep =
                JSON.parse(chatBot.baklava_schema).steps &&
                JSON.parse(chatBot.baklava_schema).steps[0];
              let start = null;
              let templateText = null;
              if (startStep) {
                const { start: tStart, templateText: tText } =
                  this.detectTypeStartStep(startStep);

                start = tStart;
                templateText = tText;
              }

              return {
                ...chatBot,
                start,
                templateText,
              };
            } else {
              const startStep = chatBot.steps && chatBot.steps[0];
              let start = null;
              let templateText = null;
              if (startStep) {
                const { start: tStart, templateText: tText } =
                  this.detectTypeStartStep(startStep);

                start = tStart;
                templateText = tText;
              }

              return {
                ...chatBot,
                start,
                templateText,
              };
            }
          })
          .sort((a, b) => {
            if (
              [
                ChatbotsStatus.active,
                ChatbotsStatus.test,
                ChatbotsStatus.ready,
              ].includes(a.status)
            ) {
              return -1;
            }
            if (
              [
                ChatbotsStatus.active,
                ChatbotsStatus.test,
                ChatbotsStatus.ready,
              ].includes(b.status)
            ) {
              return 1;
            }

            return +new Date(b.createdAt) - +new Date(a.createdAt);
          });
      }
    },

    async fetchSteps() {
      this.steps = await api.chatbotsStep.getByChatBotId(
        this.currentChatbot.id
      );
    },

    validateConnectionsChain(nodes: AbstractNode[]) {
      const allOutputs: any[] = [];
      const outputsCount: any = {};

      nodes.forEach((node) => {
        if (
          node.outputs &&
          (node.type === "StartNode" || node.inputs.input.connectionCount > 0)
        ) {
          allOutputs.push(...Object.values(node.outputs));
        }
      });
      allOutputs
        .filter((output) => output.port)
        .forEach((output: any) => {
          if (outputsCount[output.nodeId]) {
            outputsCount[output.nodeId] += 1;
          } else {
            outputsCount[output.nodeId] = 1;
          }
        });
      const countPerStep: { [stepId: string]: number } = {};
      const result = { isIntegrity: true, openedSteps: [] as string[] };
      const connections = this.connections;

      if (connections) {
        connections.forEach(({ stepFromId }) => {
          if (countPerStep[stepFromId]) {
            countPerStep[stepFromId] = countPerStep[stepFromId] + 1;
          } else {
            countPerStep[stepFromId] = 1;
          }
        });
      }

      for (const stepId in outputsCount) {
        const countFromUi = outputsCount[stepId];
        const realCount = countPerStep[stepId];
        if (countFromUi !== realCount) {
          result.isIntegrity = false;
          result.openedSteps.push(stepId);
        }
      }

      this.notClosedConnections = result.openedSteps;
      this.isIntegrity = result.isIntegrity;
      return result.isIntegrity
    },

    validateSteps(): boolean {
      const result: any[] = [];
      if (this.schema !== undefined) {
        const steps = this.schema?.steps;
        for (const step of steps) {
          const stepValidateResult = this.validate(step);
          if (!stepValidateResult.success) {
            result.push({
              id: step.id,
              ...stepValidateResult,
            });
          }
        }
      }

      if (!result.length) return true;
      const { showToast } = useToast();
      for (const item of result) {
        showToast({
          text: `Ошибка запуска бота.\n` + item.error,
          color: "error",
        });
      }
      return false;
    },

    validate(step: ChatbotStep) {
      switch (true) {
        case step.type === ChatBotStepType.action &&
          step.state.nodeType === "InputNode":
          if (!step.state.fileInput) {
            return {
              success: false,
              error:
                "Загрузите недостающие вложения и попробуйте активировать снова.",
            };
          }
          return {
            success: true,
            error: "",
          };
        case step.type === ChatBotStepType.action &&
          step.state.nodeType === "MessageNode":
          if (step.state.text.trim() === "" || !step.state.text) {
            return {
              success: false,
              error:
                "Заполните карточки сообщение и попробуйте активировать снова.",
            };
          }
          if (step.state.text.includes("{{[object PointerEvent]}}")) {
            return {
              success: false,
              error: "В карточках сообщение есть незаполненные переменные.",
            };
          }
          return {
            success: true,
            error: "",
          };
        case step.type === ChatBotStepType.action &&
          step.state.nodeType === "TemplateWabaNode":
          if (step.function.send.variables) {
            const listVar = Object.values(step.function.send.variables);
            if (listVar.length) {
              if (listVar.includes("")) {
                return {
                  success: false,
                  error:
                    "В карточках шаблоны WABA есть незаполненные переменные.",
                };
              } else {
                return {
                  success: true,
                  error: "",
                };
              }
            } else {
              return {
                success: true,
                error: "",
              };
            }

          }
          return {
            success: true,
            error: "",
          };
        case step.type === ChatBotStepType.webhook &&
          step.state.nodeType === "ActionNode":
          if (
            step.state.currentAction === "Отправить вебхук вовне" &&
            !isUrl(step.state.currentWebhookUrl)
          ) {
            return {
              success: false,
              error: "Вставьте URL в вебхук и попробуйте активировать снова.",
            };
          }
          return {
            success: true,
            error: "",
          };
        case step.type === ChatBotStepType.step &&
          step.state.nodeType === "IfNode":
          if (
            step.state.conditionType === "db" &&
            step.state.conditionTarget === "Колонка"
          ) {
            return {
              success: false,
              error:
                "Выберите колонку в карточке условие и попробуйте активировать снова.",
            };
          }
          if (
            step.state.conditionType === "db" &&
            step.state.conditionTarget !== "Колонка"
          ) {
            const contactsStore = useContacts();
            const currentField = contactsStore.getEntireFields.find(({ id }) =>  id === step.state.conditionTarget)!
            const error = {
              success: false,
              error:
                "Введите значение для сравнения в карточке условие и попробуйте активировать снова.",
            }

            if (['date', 'bDate'].includes(currentField.type)) {
              if (!step.state.dateValue) return error;
            }
            if (['int', 'numb'].includes(currentField.type)) {
              if (!step.state.numberValue) return error;
            }
            if (['text', 'customer', 'tag'].includes(currentField.type)) {
              if (!step.state.textValue) return error;
            }
          }
          if(step.state.conditionType === "time" && step.state.workingDaysTimeAccept){
            if(
              step.state.workingDaysTimeAccept.split('-').includes('') ||
              step.state.workingDaysTimeCancel.split('-').includes('') ||
              step.state.weekendDaysTimeAccept.split('-').includes('') ||
              step.state.weekendDaysTimeCancel.split('-').includes('')
            ) {
              return {
                success: false,
                error:
                  "Введите рабочее и/или выходное времени в карточке условие и попробуйте активировать снова.",
              };
            }
            if (!this.validateWorkTime(step)) {
              return {
                success: false,
                error:
                  "Обнаружено пересечение рабочего времени в карточке условие.",
              };
            }
            if (step.state.workingDays.length < 6) {
              if (!this.validateWeekendTime(step)) {
                return {
                  success: false,
                  error:
                    "Обнаружено пересечение выходного времени в карточке условие.",
                };
              }
            }
          }
          if (
            step.state.conditionType === "answer" &&
            ((step.state.formatCheck === "text" && !step.state.textValue) ||
              (step.state.formatCheck === "number" &&
                !step.state.numberValue) ||
                (step.state.formatCheck === "date" && !step.state.dateValue)
              ) &&
            step.state.formatCheck !== "phone" &&
            step.state.formatCheck !== "email"
          ) {
            return {
              success: false,
              error:
                "Введите значение для сравнения ответа пользователя в карточке условие и попробуйте активировать снова.",
            };
          }
          if (
            step.state.currentAction === "Отправить вебхук вовне" &&
            !isUrl(step.state.currentWebhookUrl)
          ) {
            return {
              success: false,
              error: "Вставьте URL в вебхук и попробуйте активировать снова.",
            };
          }
          return {
            success: true,
            error: "",
          };
        case step.type === ChatBotStepType.action &&
          step.state.nodeType === "ActionNode":
          if (!step.state.currentAction) {
            return {
              success: false,
              error: "Выберите действие и попробуйте активировать снова.",
            };
          }
          if (
            step.state.currentAction === "Записать ответ в базу" &&
            !step.state.currentField
          ) {
            return {
              success: false,
              error:
                "Выберите колонку для записи в базу и попробуйте активировать снова.",
            };
          }
          if (
            step.state.currentAction === "Удалить тег" &&
            !step.state.currentTag
          ) {
            return {
              success: false,
              error:
                "Выберите тег который хотите удалить и попробуйте активировать снова.",
            };
          }
          if (
            step.state.currentAction === "Добавить тег" &&
            !step.state.currentTag
          ) {
            return {
              success: false,
              error:
                "Выберите тег который хотите добавить и попробуйте активировать снова.",
            };
          }
          return {
            success: true,
            error: "",
          };

        default:
          return {
            success: true,
            error: "",
          };
      }
    },

    validateWorkTime(step: ChatbotStep){
      const workDayAccept = step.state.workingDaysTimeAccept.split('-')
      const workDayCancel = step.state.workingDaysTimeCancel.split('-')
      if (Number(workDayAccept[0].split(':')[0]) < Number(workDayCancel[0].split(':')[0]) ||
          (Number(workDayAccept[0].split(':')[0]) === Number(workDayCancel[0].split(':')[0]) &&
            Number(workDayAccept[0].split(':')[1]) < Number(workDayCancel[0].split(':')[1])
          )
      ) {
        if (Number(workDayAccept[1].split(':')[0]) < Number(workDayCancel[0].split(':')[0]) ||
            (Number(workDayAccept[1].split(':')[0]) === Number(workDayCancel[0].split(':')[0]) &&
            Number(workDayAccept[1].split(':')[1]) < Number(workDayCancel[0].split(':')[1])
            )
          ) {
            return true
        } else {
          return false
        }
      } else {
        if (Number(workDayCancel[1].split(':')[0]) < Number(workDayAccept[0].split(':')[0]) ||
            (Number(workDayCancel[1].split(':')[0]) === Number(workDayAccept[0].split(':')[0]) &&
            Number(workDayCancel[1].split(':')[1]) < Number(workDayAccept[0].split(':')[1])
            )
          ) {
            return true
        } else {
          return false
        }
      }
    },
    validateWeekendTime(step: ChatbotStep){
      const weekendDayAccept = step.state.weekendDaysTimeAccept.split('-')
      const weekendDayCancel = step.state.weekendDaysTimeCancel.split('-')

      if (Number(weekendDayAccept[0].split(':')[0]) < Number(weekendDayCancel[0].split(':')[0]) ||
          (Number(weekendDayAccept[0].split(':')[0]) === Number(weekendDayCancel[0].split(':')[0]) &&
            Number(weekendDayAccept[0].split(':')[1]) < Number(weekendDayCancel[0].split(':')[1])
          )
      ) {
        if (Number(weekendDayAccept[1].split(':')[0]) < Number(weekendDayCancel[0].split(':')[0]) ||
            (Number(weekendDayAccept[1].split(':')[0]) === Number(weekendDayCancel[0].split(':')[0]) &&
            Number(weekendDayAccept[1].split(':')[1]) < Number(weekendDayCancel[0].split(':')[1])
            )
          ) {
            return true
        } else {
          return false
        }
      } else {
        if (Number(weekendDayCancel[1].split(':')[0]) < Number(weekendDayAccept[0].split(':')[0]) ||
            (Number(weekendDayCancel[1].split(':')[0]) === Number(weekendDayAccept[0].split(':')[0]) &&
            Number(weekendDayCancel[1].split(':')[1]) < Number(weekendDayAccept[0].split(':')[1])
            )
          ) {
            return true
        } else {
          return false
        }
      }
    },

    async closeNonIntegrityChain({
      nodes,
      chatBotId,
      withEmit = true,
      nodeAction,
      getBackendJson,
    }: {
      nodes: AbstractNode[];
      withEmit: boolean | undefined;
      chatBotId: string;
      nodeAction: AbstractNode;
      getBackendJson: ({
        node,
        chatBotId,
        userId,
      }: {
        node: any;
        chatBotId: any;
        userId: string;
      }) => any;
    }) {
      const nodesById: {
        [nodeId: string]: AbstractNode & { position: { x: number; y: number } };
      } = {};
      const newConnections: {
        [nodeId: string]: {
          stepFromId: string;
          coordX: number;
          coordY: number;
          outputs: string[];
        };
      } = {};
      const addedConnections = [];
      nodes.forEach((node: any) => (nodesById[node.id] = node));
      this.notClosedConnections.forEach((nodeId) => {
        const node = nodesById[nodeId];
        Object.entries(node.outputs)
          .filter(
            ([_, output]: any) => output._connectionCount < 1 && output.port,
          )
          .forEach(([outputKey, _]: any) => {
            if (newConnections[nodeId]) {
              newConnections[nodeId].outputs.push(outputKey);
            } else {
              newConnections[nodeId] = {
                coordX: node.position.x + 320 + 50,
                coordY: node.position.y,
                stepFromId: nodeId,
                outputs: [outputKey],
              };
            }
          });
      });

      if (Object.keys(newConnections).length) {
        for (const nodeId in newConnections) {
          const { outputs, coordX, coordY, stepFromId } =
            newConnections[nodeId];
          // @ts-ignore
          const nn = new nodeAction({
            if: [],
            nodeType: 'ActionNode',
            currentAction: 'Остановить бота',
          });
          const id = uuidv4();
          nn.id = id;
          this.baklava.displayedGraph.addNode(nn);
          nn.position.x = coordX;
          nn.position.y = coordY;
          if (nn.inputs.input) {
            nn.inputs.input.nodeId = id;
          }
          if (nn.outputs) {
            for (const output in nn.outputs) nn.outputs[output].nodeId = id;
          }

          for (const outputKey of outputs) {
            const nodeFrom = nodes.find(({ id }: any) => id === stepFromId)!;
            const output = nodeFrom.outputs[outputKey];
            this.baklava.displayedGraph.addConnection(output, nn.inputs.input);
            addedConnections.push({
              stepFromId,
              stepToId: id,
              outputKey,
              shadowStepId: uuidv4(),
            });
          }
        }
      }

      if (withEmit) {
        const { send: sendSocket } = useChatbotsWs();

        const steps = this.baklava.displayedGraph.nodes.map((item: any) => {
          return getBackendJson({
            node: item,
            chatBotId: chatBotId,
            userId: this.currentChatbot.userId,
          });
        });
        const connections = [...this.connections!, ...addedConnections];
        const schema = {
          steps: steps,
          connections: connections,
        };

        this.setSchema(schema);
        sendSocket('save-schema', {
          graph: JSON.stringify(this.schema),
          chatBotId: chatBotId,
        });
        return true;
      }

      return false;
    },

    setFetching(payload: boolean) {
      this.isFetching = payload;
    },

    async removeChatbotById(chatBotId: string): Promise<boolean> {
      this.setFetching(true);

      const { pushNotification } = useNotification();
      const { showToast } = useToast();

      try {
        await api.chatbots.delete(chatBotId);
        await this.fetchChatbots();

        showToast({
          text: $t("chatbots.chatBotRemoved"),
          color: "default",
        });

        return true;
      } catch (e: any) {
        console.log("error: ", e);

        pushNotification({
          message: e.response.data.error.message,
          type: NotificationType.error,
        });

        return false;
      } finally {
        this.setFetching(false);
      }
    },

    async disableActiveBot(id: string) {
      await api.chatbots.disable(id);

      const updateIndex = this.chatbots.findIndex(
        ({ id: chatbotId }) => chatbotId === id
      );

      if (updateIndex !== -1) {
        const updated = {
          ...this.chatbots[updateIndex],
          status: ChatbotsStatus.draft,
        };
        this.chatbots.splice(updateIndex, 1, updated);
      }
    },

    async updateChatbot(id: string, payload: Partial<ChatBotType>) {
      const { data } = await api.chatbots.update<Partial<Chatbot>>(id, payload);
      const updateIndex = this.chatbots.findIndex(
        ({ id: chatbotId }) => chatbotId === id,
      );

      if (updateIndex !== -1) {
        const updated = { ...this.chatbots[updateIndex], ...data };
        this.chatbots.splice(updateIndex, 1, updated);
      }

      return data;
    },

    async fetchStepStatistics() {
      if (!this.currentChatbot.id) return;
      this.stepStatistics = await api.chatbots.getStatistics(
        this.currentChatbot.id
      );
    },

    setCurrent(payload: ChatBotType) {
      this.currentChatbot = payload;
    },

    setShowTestModal(payload: boolean) {
      this.showTestModal = payload;
    },

    setIsLoadingFlagForTestModal(flag: boolean) {
      this.isLoadingFlagForTestModal = flag;
    },

    setIsIntegrity(flag: boolean) {
      this.isIntegrity = flag;
    },

    pushStep(payload: ChatbotStep) {
      this.steps.push(payload);
    },

    toggleStep(payload: ChatbotStep) {
      const index = this.steps.findIndex(({ id }) => id === payload.id);

      if (index !== -1) {
        this.steps.splice(index, 1, payload);
      } else this.pushStep(payload);
    },

    removeStep(payload: string) {
      this.steps = this.steps.filter(({ id }) => id !== payload);
    },

    setTypeStartStep(payload: ChatbotStep) {
      const index = this.chatbots.findIndex(
        ({ id }) => id === payload.chatBotId
      );
      const typeStart = this.detectTypeStartStep(payload);
      if (index !== -1) {
        this.chatbots.splice(index, 1, {
          ...this.chatbots[index],
          ...typeStart,
        });
      }
    },

    detectTypeStartStep(payload: ChatbotStep): {
      start: ChatBotStartType | null;
      templateText: string | null;
    } {
      const templateStore = useTemplates();
      const result: {
        start: ChatBotStartType | null;
        templateText: string | null;
      } = {
        start: null,
        templateText: null,
      };

      if (payload.type === ChatBotStepType.start) {
        if (Object.values(payload.function.send).length) {
          result.start = "outgoing";
          result.templateText =
            templateStore.getTemplatesMap[payload.function.send?.text]?.title ||
            null;
        } else {
          result.start = "incoming";
        }
      }

      return result;
    },

    async disableTestingBot() {
      const id = this.getCurrent?.id;

      if (id) {
        await api.chatbots.disableTesting(id);
        const updateIndex = this.chatbots.findIndex(
          ({ id: chatbotId }) => chatbotId === id
        );
        if (updateIndex !== -1) {
          const updated = {
            ...this.chatbots[updateIndex],
            status: ChatbotsStatus.draft,
          };
          this.chatbots.splice(updateIndex, 1, updated);
        }
      }
    },

    async activateChatBot(chatBotId: string) {
      await api.chatbots.enable({
        chatBotId,
        schema: JSON.stringify(this.schema)
      });
    },
  },

  getters: {
    getStats: (state: State) => state.stats,
    getChatbots: (state: State) => state.chatbots,
    getWorkingDays: (state: State) => state.workingDays,
    getActiveChatbots: (state: State) =>
      state.chatbots.filter((item) =>
        [ChatbotsStatus.active, ChatbotsStatus.ready].includes(item.status)
      ),
    getDisabledChatbots: (state: State) =>
      state.chatbots.filter((item) =>
        [ChatbotsStatus.draft].includes(item.status)
      ),
    getTemplateChatbots: (state: State) => state.templateChatBots,
    getFetching: (state: State) => state.isFetching,
    getCurrent: (state: State) => state.currentChatbot,
    hasChatbotDisabled: () => {
      const userStore = useUser();
      const tariff = userStore.connectedTariff;

      return !tariff || !tariff.hasChatBot;
    },
    isTemplateStart: (state: State) => state.templateStartType,
    showModal: (state: State) => state.showTestModal,
    getIsLoadingFlagForTestModal: (state: State) =>
      state.isLoadingFlagForTestModal,
    getIsIntegrity: (state: State) => state.isIntegrity,
    getIncomingActiveBots: (state: State) => {
      return state.chatbots.filter(({ start, channel, status }) => {
        return (
          start === 'incoming' &&
          channel!.transport === ChannelsTransportType.wapi &&
          status === ChatbotsStatus.active
        );
      });
    },

    // for template node
    wabaNodes(state: State) {
      return (nodeId: string) =>
        state.steps
          .filter(
            ({ state, type, id }) =>
              id !== nodeId &&
              (state?.nodeType === "TemplateWabaNode" ||
                (type === "start" && state?.currentTab === "outgoing"))
          )
          .sort(
            (a, b) =>
              +new Date(a.state.templateSetAt) -
              +new Date(b.state.templateSetAt)
          );
    },
    wabaNodesByCategory() {
      return (nodeId: string) => ({
        [TemplateCategoryType.MARKETING]: this.wabaNodes(nodeId).filter(
          ({ state }) =>
            state.templateCategory === TemplateCategoryType.MARKETING
        ),
        [TemplateCategoryType.UTILITY]: this.wabaNodes(nodeId).filter(
          ({ state }) => state.templateCategory === TemplateCategoryType.UTILITY
        ),
        [TemplateCategoryType.AUTHENTICATION]: this.wabaNodes(nodeId).filter(
          ({ state }) =>
            state.templateCategory === TemplateCategoryType.AUTHENTICATION
        ),
      });
    },
  },
});
